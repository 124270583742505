module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.0_uiujl7r7bgikaxfoubfeu47oiq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dallen Hoyal Portfolio","short_name":"Dallen Hoyal","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"browser","icon":"./src/images/global/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"14b25741a43916f719d1c6320113cdc5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.14.0_babel-plugin-styled-components@2.1.4_@babel+core@7.26._oixos3ymd4z2liuubcxuuxaduq/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.0.0_react@18.0.0__react@18.0.0__eckvi5my3e3hvjr4y2arcwmeym/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
