import { useState, createContext } from 'react'

export const CursorContext = createContext("")

const Provider = ({children}) => {

  const [cursorState, setCursorState] = useState("")

  return (
    <CursorContext.Provider value={{state: cursorState, setState: setCursorState}}>
      {children}
    </CursorContext.Provider>
  )
}

export default Provider
