import { ScreenProvider } from "library/ScreenContext"
import StyledManager from "library/StyledManager"
import ColorProvider, { PrimaryColorContext } from './ColorProvider'
import CursorProvider, { CursorContext } from './CursorProvider'
import InitProvider, { InitAnimationContext } from './InitProvider'

interface ProvidersProps {
	children: React.ReactNode
}

/**
 * providers here will be mounted once, and will never unmount
 */
export function RootProviders({ children }: ProvidersProps) {
	return (
    <ScreenProvider>
      <CursorProvider>
        <InitProvider>
          <ColorProvider>
            {children}
          </ColorProvider>
        </InitProvider>
      </CursorProvider>
    </ScreenProvider>
  )
}

/**
 * providers here will be unmounted and remounted on every route change
 */
export function RouteProviders({ children }: ProvidersProps) {
	return <StyledManager>{children}</StyledManager>
}

export {
  PrimaryColorContext,
  CursorContext,
  InitAnimationContext
}
