import { type Config, defaultConfig } from "library/defaultConfig"

/**
 * The transition names that can be used in the page transition
 */
export type TransitionNames = never

const config: Config = {
	...defaultConfig,
  scaleFully: false,
  scrollRestoration: false,
}

export default config
