import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { PrimaryColorContext } from 'components/Providers'
import { fresponsive, fmobile } from 'library/fullyResponsive'
import ConstantMarquee from 'library/ConstantMarquee'

import colors from 'styles/colors'

import { ReactComponent as GlobeSVG } from 'images/globeIcon.svg'
import { ReactComponent as PointSVG } from 'images/pointIcon.svg'
import { Container } from 'styles/uiElements'

const NameMarquee = () => {

  const primaryColor = useContext(PrimaryColorContext)

  return (
    <Wrapper>
      <ConstantMarquee speed={5}>
        <Row>
          <Globe $primaryColor={primaryColor.state}/>
          <P>Creative Developer</P>
          <Point $primaryColor={primaryColor.state}/>
          <P>ウェブ開発者&nbsp;&nbsp;DALLEN HOYAL</P>
        </Row>
      </ConstantMarquee>
    </Wrapper>
  )
}

export default NameMarquee

const Wrapper = styled(Container)`
  ${fmobile(css`
    max-width: 382.92px;
  `)}
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const svgStyles = css`
  will-change: transform;

  ${fresponsive(css`
    width: 29.33px;
    height: 29.33px;
    padding-top: 4.23px;
    padding-bottom: 4.23px;
  `)}

  ${fmobile(css`
    width: 20.54px;
    height: 20.54px;
    padding-top: 11.43px;
    padding-bottom: 11.43px;
  `)}
`

const Globe = styled(GlobeSVG)`
  ${svgStyles}

  path, circle {
    stroke: ${props => props.$primaryColor};
  }
`

const Point = styled(PointSVG)`
  ${svgStyles}

  path, circle {
    stroke: ${props => props.$primaryColor};
    filter: drop-shadow(0px 0px 1.7px rgba(255, 235, 235, 0.5));
  }
`

const P = styled.p`
  // font-family: MD Primer Test;
  font-family: MD IO;
  font-style: normal;
  font-weight: bold;
  line-height: 100%;
  color: ${colors.white};
  will-change: transform;
  
  ${fresponsive(css`
    font-size: 20.17px;
    margin-left: 16.78px;
    margin-right: 16.78px;
    padding-top: 4.23px;
    padding-bottom: 4.23px;
  `)}

  ${fmobile(css`
    font-size: 18.28px;
    margin-left: 5.69px;
    margin-right: 5.69px;
    padding-top: 11.43px;
    padding-bottom: 11.43px;
  `)}
`
