import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { PrimaryColorContext } from 'components/Providers'
import { fresponsive, fmobile } from 'library/fullyResponsive'

import colors from 'styles/colors'

import { ReactComponent as PointSVG } from 'images/pointIcon.svg'

const InfoRow = () => {

  const primaryColor = useContext(PrimaryColorContext)

  return (
    <Wrapper>
      <Point1 $primaryColor={primaryColor.state}/>
      <Squares>
        <Square color={primaryColor.state}/>
        <Square color={colors.white}/>
        <Square color={colors.gray}/>
        <Square color={colors.darkGray}/>
      </Squares>
      <Location>Salt Lake City, UT</Location>
      <Email>dallen.hoyal@gmail.com</Email>
      <Point2 $primaryColor={primaryColor.state}/>
      <Point3 $primaryColor={primaryColor.state}/>
    </Wrapper>
  )
}

export default InfoRow

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${fresponsive(css`
    height: 29.35px;
    margin-top: 83.85px;
  `)}

  ${fmobile(css`
    height: 23.97px;
    margin-top: 45.67px;
  `)}
`

const Point = styled(PointSVG)`
  height: auto;
  ${fresponsive(css`
    width: 29.35px;
  `)}

  ${fmobile(css`
    width: 22.81px;
  `)}

  circle, path {
    transition: 500ms;
    stroke: ${props => props.$primaryColor};
    filter: drop-shadow(0px 0px 1.7px rgba(255, 235, 235, 0.5));
  }
`

const Point1 = styled(Point)`
  position: absolute;
  left: 0;
  top: 0;
`

const Point2 = styled(Point)`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  ${fmobile(css`
    display: none;
  `)}
`

const Point3 = styled(Point)`
  position: absolute;
  top: 0;
  right: 0;
`

const Squares = styled.div`
  display: flex;
  position: absolute;
  
  ${fresponsive(css`
    top: 4.2px;
    left: 67.06px;
  `)}

  ${fmobile(css`
    top: 50%;
    transform: translateY(-50%);
    left: 45.67px;
  `)}
`

const Square = styled.div`
  background: ${props => props.color};
  transition: 500ms;

  ${fresponsive(css`
    width: 21.8px;
    height: 21.8px;
  `)}

  ${fmobile(css`
    width: 18.28px;
    height: 18.28px;
  `)}
`

const P = styled.p`
  color: ${colors.white};
  position: absolute;
  font-family: MD IO;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.02em;
  
  ${fresponsive(css`
    font-size: 15.09px;
    text-shadow: 0vw 0px 4.2px ${colors.white};
  `)}

  ${fmobile(css`
    font-size: 15.96px;
  `)}
`

const Location = styled(P)`
  ${fresponsive(css`
    top: 4.2px;
    left: 175.56px;
  `)}

  ${fmobile(css` 
    display: none;
  `)}
`

const Email = styled(P)`
  ${fresponsive(css`
    top: 4.2px;
    right: 49.8px;
  `)}

  ${fmobile(css`
    top: 50%;
    transform: translateY(-50%);
    left: 141.24px;
  `)}
`
