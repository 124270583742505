import { useState, createContext } from 'react'

import colors from 'styles/colors'

export const PrimaryColorContext = createContext(colors.purple)

const Provider = ({children}) => {

  const [primaryColor, setPrimaryColor] = useState(colors.js.purple)

  return (
    <PrimaryColorContext.Provider value={{state: primaryColor, setState: setPrimaryColor}}>
      {children}
    </PrimaryColorContext.Provider>
  )
}

export default Provider
