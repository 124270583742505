import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { PrimaryColorContext } from './Providers'

import colors from 'styles/colors'
import { desktopBreakpoint } from 'styles/media'

import NameMarquee from 'components/NameMarquee'
import InfoRow from 'components/InfoRow'
import { fresponsive, fmobile } from 'library/fullyResponsive'

const Footer = () => {

  const primaryColor = useContext(PrimaryColorContext)

  return (
    <Wrapper>
      <Inner>
        <InfoRow/>
        <Name $primaryColor={primaryColor.state}>Dallen Hoyal</Name>
        <NameMarquee/>
      </Inner>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.footer`
  width: 100%;
  display: grid;
  place-items: center;
`

const Inner = styled.footer`
  width: 100%;
  max-width: ${desktopBreakpoint}px;
  display: flex;
  flex-direction: column;

  ${fresponsive(css`
    padding-left: 58.68px;
    padding-right: 58.68px;
    padding-bottom: 109px;
  `)}

  ${fmobile(css`
    padding-left: 22.81px;
    padding-right: 22.81px;
    padding-bottom: 45.67px;
  `)}
`

const Name = styled.span`
  font-family: Ofform;
  font-style: normal;
  font-weight: normal;
  line-height: 70%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.white};
  filter: blur(1px);
  transition: text-shadow 0.5s;

  ${(props) => fresponsive(css`
    text-shadow: 0px 0px 16.77px rgba(255, 255, 255, 0.25), 4.2px -4.2px 16.77px ${props.$primaryColor}, -4.2px 4.2px 8.38px ${props.$primryColor};
    font-size: 188.64px;
    margin-top: 16.77px;
    margin-bottom: 16.77px;
  `)}

  ${fmobile(css`
    font-size: 42.8px;
    margin-top: 22.81px;
    margin-bottom: 22.81px;
  `)}
`
