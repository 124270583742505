/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
import "styles/fonts/typography.css"
import "the-new-css-reset/css/reset.css"

import Layout from "components/Layout"
import { RootProviders, RouteProviders } from "components/Providers"
import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { ScrollTrigger } from "gsap/ScrollTrigger"

/**
 * global plugin registration. be sure to also register plugins in gatsby-ssr.ts so that they are available during SSR
 */
gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

/**
 * these will only mount once, and will never unmount
 * this cannot contain UI
 */
export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
	return <RootProviders>{element}</RootProviders>
}

/**
 * these will unmount and remount on every route change
 */
export const wrapPageElement = ({ element }: { element: React.ReactNode }) => {
	return (
		<RouteProviders>
			<Layout>{element}</Layout>
		</RouteProviders>
	)
}

// disable GSAP's null target warning
gsap.config({
	nullTargetWarn: false,
})
